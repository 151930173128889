import React from "react";
import logo_real from "./matt500.jpg";
import logo_cartoon from "./parappa500.jpg";
import "./App.css";

// So I believe this uses a proper old React structure, but the site is probably be replaced soon :))
class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCartoon: false,
    };
  }

  handleClick() {
    this.setState({
      isCartoon: !this.state.isCartoon,
    });
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img
            className="App-logo"
            alt="Me!"
            onClick={() => this.handleClick()}
            src={this.state.isCartoon ? logo_cartoon : logo_real}
          />
          <div className="App-content-align">
            <h1>Matt Hall</h1>
            {
              !this.state.isCartoon
              ? <p>
                  PhD student in computer science at Swansea University working with Velindre Cancer Centre in Cardiff on digital PROMs. My site's not quite ready yet, but check out my socials below!
                </p>
              : <p>
                  yer da sells avon
                </p>
            }
            
            <div className="App-gradient-bar"/>
            <div className='social-container'>
              <a
                className="App-link"
                href={this.state.isCartoon ? "https://twitter.om/mhmatthall" : "https://twitter.com/matthallSU"}
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter
              </a>
              <a
                className="App-link"
                href="https://github.com/mhmatthall/"
                target="_blank"
                rel="noopener noreferrer"
              >
                GitHub
              </a>
              <a
                className="App-link"
                href="https://linkedin.com/in/mhmatthall/"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn
              </a>
              <a
                className="App-link"
                href="https://pch.wales/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Project
              </a>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default App;
